import { MUTATION_RESULT_FIELDS } from "@/types/Fragments";
import { gql } from "@apollo/client";
import { MutationResult } from "../types/MutationResult";

import {
  IAddOrganizationBroker,
  IAddProduct,
  IBaseOrganization,
  IDeleteProduct,
  IEditOrganization,
  IGetInvite,
  IOrganization,
  ISendInvites,
  OrganizationStatus,
  OrganizationType,
} from "../types/Organization";
import { BrokerRole, IBrokerDetails, IChangePassword } from "../types/User";

export interface IAddOrganizationResponse {
  addOrganization: MutationResult;
}

export interface IAddOrganizationRequest {
  input: {
    type: OrganizationType;
    name: string;
    shortCode?: string;
    description: string;
    status: OrganizationStatus;
    address?: string;
    phoneNumber?: string;
    parentId?: string;
  };
}

export const ADD_ORGANIZATION = gql`
  mutation AddOrganization($input: InputOrganizationCreate!) {
    addOrganization(input: $input) {
      status
      processId
      id
    }
  }
`;

export interface IEditOrganizationResponse {
  editOrganization: MutationResult;
}

export interface IEditOrganizationRequest {
  input: IEditOrganization;
}

export const EDIT_ORGANIZATION = gql`
  mutation EditOrganization($input: InputOrganizationEdit!) {
    editOrganization(input: $input) {
      id
      processId
      status
    }
  }
`;

export interface IGetOrganizationResponse {
  getOrganization: IOrganization | null;
}

export interface IGetOrganizationRequest {
  id: string;
}

export const GET_ORGANIZATION = gql`
  query GetOrganization($id: String!) {
    getOrganization(id: $id) {
      id
      created
      name
      description
      status
      type
      address
      phoneNumber
      zipcode
      city
      state
      agencies {
        id
        created
        name
        status
        brokers {
          role
          givenName
          surName
        }
      }
      brokers {
        brokerId
        role
        givenName
        mail
        surName
        accountEnabled
      }
      products
    }
  }
`;

export interface IGetOrganizationsResponse {
  getOrganizations: IOrganization[];
}
export interface IGetOrganizationsDropDownResponse {
  getOrganizations: IBaseOrganization[];
}
export interface IGetOrganizationsRequest {}

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    getOrganizations {
      id
      created
      name
      description
      status
      type
      address
      phoneNumber
      brokers {
        brokerId
        role
        mail
        surName
        givenName
        accountEnabled
      }
      products
    }
  }
`;

export interface IGetOrganizationsByBrokerRequest {
  id: string;
}

export const GET_ORGANIZATIONS_BY_BROKER = gql`
  query GetOrganizationsByBroker($id: String!) {
    getOrganizationsByBrokerId(id: $id) {
      id
      name
      agencies {
        id
        name
      }
    }
  }
`;

export const GET_ORGANIZATIONS_DROPDOWN = gql`
  query GetOrganizations {
    getOrganizations {
      id
      name
    }
  }
`;

export interface ICreateBrokerResponse {
  createBroker: boolean;
}

export interface ICreateBrokerRequest {
  input: {
    brokerId?: string;
    role?: string;
    givenName: string;
    surName: string;
    accountEnabled: boolean;
    mail: string;
    passwordProfile: {
      forceChangePasswordNextSignIn: boolean;
      password: string;
    };
    organizationId: string;
    city?: string;
    mobilePhone?: string;
    postalCode?: string;
    state?: string;
    streetAddress?: string;
  };
}
export interface IVerifyBrokerRequest {
  input: {
    mail: string;
    verificationCode: string;
  };
}

export interface IVerifyBroker {
  data: {
    user: string;
    verificationCode: string;
  };
  token: string;
}

export const CREATE_BROKER = gql`
  mutation CreateBroker($input: InputCreateBroker!) {
    createBroker(input: $input)
  }
`;

export interface IChangePasswordResponse {
  changePassword: boolean;
}

export interface IChangePasswordRequest {
  input: IChangePassword;
}

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: InputChangePassword!) {
    changePassword(input: $input)
  }
`;
export interface IUpdateBrokerResponse {
  updateBroker: boolean;
}

export interface IUpdateBrokerRequest {
  input: {
    id: string;
    role?: BrokerRole;
    brokerId: string;
    city?: string;
    givenName?: string;
    mail?: string;
    mobilePhone?: string;
    postalCode?: string;
    state?: string;
    streetAddress?: string;
    streetAddress2?: string;
    surName?: string;
    accountEnabled?: boolean;
    photoId?: string;
    npn?: string;
    isOnboardingCompleted?: boolean;
    sellingStates?: string[];
  };
}

export const UPDATE_BROKER = gql`
  mutation UpdateBroker($input: InputUpdateBroker!) {
    updateBroker(input: $input)
  }
`;

export const CHANGE_BROKER_EMAIL = gql`
  mutation ChangeBrokerEmail($email: String!, $brokerId: String!) {
    changeBrokerEmail(email: $email, brokerId: $brokerId)
  }
`;

export interface IChangeBrokerEmailRequest {
  brokerId: string;
  email: string;
}

export interface IChangeBrokerEmailResponse {
  changeBrokerEmail: boolean;
}

export interface IAddOrUpdateOrganizationBrokersResponse {
  addOrUpdateOrganizationBrokers: IOrganization;
}

export interface IAddOrUpdateOrganizationBrokersRequest {
  input: {
    id: string;
    brokers: IAddOrganizationBroker[];
  };
}

export const ADD_OR_UPDATE_ORGANIZATION_BROKER = gql`
  mutation AddOrUpdateOrganizationBrokers($input: InputAddBrokers!) {
    addOrUpdateOrganizationBrokers(input: $input) {
      id
      name
      description
      status
      address
      city
      state
      zipcode
      phoneNumber
      brokers {
        brokerId
        role
        user {
          name
          accountEnabled
        }
      }
    }
  }
`;

export interface IRemoveOrganizationBrokersResponse {
  removeOrganizationBrokers: IOrganization;
}

export interface IRemoveOrganizationBrokersRequest {
  input: {
    id: string;
    brokers: string[];
  };
}

export const REMOVE_ORGANIZATION_BROKERS = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation RemoveOrganizationBrokers($input: InputRemoveBrokers!) {
    removeOrganizationBrokers(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface IGetOrganizationBrokersResponse {
  getOrganizationBrokers: IBrokerDetails[];
}

export interface IGetOrganizationBrokersRequest {
  id: string;
}

export const GET_ORGANIZATION_BROKERS = gql`
  query GetOrganizationBrokers($id: String!) {
    getOrganizationBrokers(id: $id) {
      organizationId
      brokerId
      role
      user {
        name
        accountEnabled
      }
    }
  }
`;

export interface IGetBrokersResponse {
  getBrokers: IBrokerDetails[];
}

export interface IGetBrokersRequest {
  id?: string;
  onlyWithoutOrganization?: boolean;
}

export const GET_BROKERS = gql`
  query GetBrokers($id: ID, $onlyWithoutOrganization: Boolean) {
    getBrokers(id: $id, onlyWithoutOrganization: $onlyWithoutOrganization) {
      organizations {
        id
        name
      }
      brokerId
      role
      mail
      givenName
      surName
      accountEnabled
      products
    }
  }
`;

export interface IBrokerPaginationResult {
  brokers: IBrokerDetails[];
  count: number;
}

export interface IGetBrokersWithPaginationResponse {
  getBrokersWithPagination: IBrokerPaginationResult;
}

export interface IGetBrokersWithPaginationRequest {
  id?: string;
  onlyWithoutOrganization?: boolean;
  query?: string;
  page: number;
  pageSize: number;
}

  export const GET_BROKERS_WITH_PAGINATION = gql`
    query GetBrokersWithPagination(
      $organizationId: ID,
      $query: String,
      $page: Int!,
      $pageSize: Int!,
    ) {
    getBrokersWithPagination(
      organizationId: $organizationId,
      query: $query,
      page: $page,
      pageSize: $pageSize,
    ) {
      brokers {
        organizations {
          id
          name
        }
        brokerId
        role
        mail
        givenName
        surName
        accountEnabled
        products
      }
      count
    }
  }
`;

export interface ISendInvitesResponse {
  status: Boolean;
  ids: string[];
}

export interface ISendInvitesRequest {
  input: ISendInvites;
}

export const SEND_INVITES = gql`
  mutation SendInvites($input: InputSendInvites!) {
    sendInvites(input: $input) {
      status
      ids
    }
  }
`;

export interface IGetInviteBrokerResponse {
  getInvite: IGetInvite;
}

export interface IGetInviteBrokerRequest {
  id: string;
}

export const GET_INVITE_BROKER = gql`
  query GetInvite($id: String!) {
    getInvite(id: $id) {
      email
      role
    }
  }
`;

export interface IAddProductResponse {
  addProduct: boolean;
}

export interface IAddProductRequest {
  input: IAddProduct;
}

export const ADD_PRODUCT = gql`
  mutation AddProduct($input: InputAddProduct!) {
    addProduct(input: $input)
  }
`;

export interface IDeleteProductResponse {
  deleteProduct: boolean;
}

export interface IDeleteProductRequest {
  input: IDeleteProduct;
}

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($input: InputDeleteProduct!) {
    deleteProduct(input: $input)
  }
`;

export const UPDATE_ORGANIZATION_GROUP = gql`
  mutation ($input: InputUpdateOrganizationGroup!) {
    updateOrganizationGroup(input: $input) {
      id
      status
    }
  }
`;
