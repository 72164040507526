import { BookmarkIcon } from "@heroicons/react/24/outline";

interface IBookmarkItemPlaceholderProps {}

const BookmarkItemPlaceholder = ({}: IBookmarkItemPlaceholderProps) => {
  return (
    <div className="p-3 bg-white rounded-md border-2 border-dashed mx-2 my-4 text-center h-28 flex items-center">
      <span className="font-interRegular text-sm text-gray-500">
        Select the <BookmarkIcon width={18} className="inline align-sub" /> icon
        to add a bookmark
      </span>
    </div>
  );
};

export default BookmarkItemPlaceholder;
