import NextLink from "next/link";
import { ReactNode } from "react";

interface ILinkProps {
  href: string;
  children?: ReactNode;
  extraClasses?: string;
  className?: string;
}

const Link = ({ href, children, extraClasses, className }: ILinkProps) => {
  let classes = className ?? "text-indigo-600 font-medium";

  if (extraClasses !== undefined) classes += ` ${extraClasses}`;

  return (
    <span className={classes}>
      <NextLink href={href}>{children}</NextLink>
    </span>
  );
};

export default Link;
