import { useOnClickOutside } from "@reframe-financial/chaplin";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ProgressItem, { ProgressItemStatus } from "./ProgressItem";
import ProgressItemMinimal from "./ProgressItemMinimal";

export interface IProgressMapItem {
  title: string;
  description: string;
  status: ProgressItemStatus;
}

interface IProgressMapProps {
  items: IProgressMapItem[];
  orientation?: "vertical" | "horizontal";
}

function ProgressMap({ items, orientation }: IProgressMapProps) {
  const ref = useRef(null);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const [selectedItem, setSelectedItem] = useState<number | null>(null);

  useOnClickOutside(ref, () => setSelectedItem(null));

  const progressBarVariants = {
    hide: {
      height: "14px",
    },
    show: {
      height: "128px",
    },
  };

  return (
    <>
      <div
        className={classNames("relative flex w-full", {
          "flex-col space-y-10": orientation === "vertical",
          "flex-row justify-between": orientation === "horizontal",
          "bg-white": selectedItem !== null,
        })}
        ref={ref}
      >
        {items.map((item, i) =>
          orientation === "vertical" ? (
            <ProgressItem
              key={i}
              title={item.title}
              description={item.description}
              status={item.status}
              lastItem={i === items.length - 1}
              index={i}
            />
          ) : (
            <>
              <motion.div
                className="w-full h-32 bg-white absolute top-0 left-0 shadow-[rgba(0,0,0,0.01)_0px_2px_3px_0px]"
                initial={{
                  height: 0,
                }}
                animate={selectedItem === null ? "hide" : "show"}
                variants={progressBarVariants}
              ></motion.div>
              <ProgressItemMinimal
                key={i}
                title={item.title}
                description={item.description}
                status={item.status}
                showText={selectedItem === i}
                isLeftItem={i < items.length / 2}
                onClick={() => setSelectedItem(i)}
              />
            </>
          )
        )}
      </div>
      {/* Use following div to trigger click outside, since iFrame is not allowing it to trigger */}
      {isTabletOrMobile && selectedItem !== null && (
        <div className="absolute w-full h-[calc(100vh-84px)] opacity-0"></div>
      )}
    </>
  );
}

export default ProgressMap;
