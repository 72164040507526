import { IBrokerDetails } from "@/types/User";
import { getUserProfilePhoto } from "../pages/api/userPhotoApi";

export const BROKER_DETAILS_KEY = "broker_details";
export const downloadUserPhoto = async (photoId: string) => {
  const response = await getUserProfilePhoto(photoId);

  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const setStoredBrokerDetails = (data: IBrokerDetails) => {
  if (typeof window === "undefined") return;
  sessionStorage.setItem(BROKER_DETAILS_KEY, JSON.stringify(data));
};

export const getStoredBrokerDetails = () => {
  if (typeof window === "undefined") return;
  const rawData = sessionStorage.getItem(BROKER_DETAILS_KEY);
  return rawData ? (JSON.parse(rawData) as IBrokerDetails) : undefined;
};
