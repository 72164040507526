import { useUserContext } from "@/context/UserContext";
import React, { Dispatch, SetStateAction, useState } from "react";

interface IViewingAsAlertProps {
  setViewAsModalShown: Dispatch<SetStateAction<boolean>>;
}

function ViewingAsAlert({ setViewAsModalShown }: IViewingAsAlertProps) {
  const { setAlerted } = useUserContext();

  return (
    <>
      <div className="">
        <div
          className="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-50 z-10"
          onClick={() => setAlerted(true)}
        ></div>
        <div
          className="absolute flex items-center space-x-4 p-2 bg-shades-goldishWhite border-1 border-secondary-gold rounded-lg shadow-sm animate-ping-tiny z-20"
          onClick={() => setViewAsModalShown(true)}
        >
          <p className="text-primary-gold text-xs font-medium underline cursor-pointer py-2">
            Enable View As
          </p>
        </div>
        <div
          className="relative flex items-center space-x-4 p-2 bg-shades-goldishWhite border-1 border-secondary-gold rounded-lg shadow-sm z-20"
          onClick={() => setViewAsModalShown(true)}
        >
          <p className="text-primary-gold text-xs font-medium underline cursor-pointer py-2">
            Enable View As
          </p>
        </div>
      </div>
    </>
  );
}

export default ViewingAsAlert;
