import { getAgnesAccessToken } from "../../services/auth";

export const getUserProfilePhoto = async (photoId: string) => {
  let agnesAccessToken = await getAgnesAccessToken();
  return await fetch(
    `${process.env.NEXT_PUBLIC_AGNES_SERVICE_URL}/brokers-photos/${photoId}`,
    {
      headers: {
        Authorization: `Bearer ${agnesAccessToken}`,
      },
    }
  );
};
