import Router from "next/router";
import { useEffect } from "react";
import { paths } from "../routing/paths";
import { getUserRole } from "../services/auth";
import { RoleGroup } from "@/types/RoleGroup";

export default function Home() {
  useEffect(() => {
    const { pathname } = Router;
    if (pathname == "/") {
      const role = getUserRole();
      if (role === RoleGroup.Admin) {
        Router.push(paths.admin.organizations.list());
      } else Router.push(paths.dashboard());
    }
  }, []);

  return <div></div>;
}
