import React, { Dispatch, SetStateAction } from "react";

interface IViewingAsEnableProps {
  setViewAsModalShown: Dispatch<SetStateAction<boolean>>;
}

function ViewingAsEnable({ setViewAsModalShown }: IViewingAsEnableProps) {
  return (
    <p
      className="text-secondary-lightBlue px-2.5 cursor-pointer text-xs underline"
      onClick={() => setViewAsModalShown(true)}
    >
      Enable View As
    </p>
  );
}

export default ViewingAsEnable;
