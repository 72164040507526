import { ProgressItemStatus } from "@reframe-financial/chaplin";
import ProgressMap from "../shared/Progress/ProgressMap";

export enum OnBoardingStep {
  WELCOME,
  CONTACT_INFORMATION,
  AGENT_INFORMATION,
  PHOTO,
}

interface IOnBoardingProgress {
  currentStep: OnBoardingStep;
  orientation?: "vertical" | "horizontal";
}

const OnBoardingProgress = ({
  currentStep,
  orientation,
}: IOnBoardingProgress) => {
  const mapCurrentStepToStatus = (
    currentStep: OnBoardingStep,
    targetStep: OnBoardingStep
  ) =>
    currentStep === targetStep
      ? ProgressItemStatus.ONGOING
      : currentStep > targetStep
      ? ProgressItemStatus.COMPLETED
      : ProgressItemStatus.UPCOMING;

  return (
    <ProgressMap
      items={[
        {
          title: "Welcome",
          description: "Welcome to Reframe Financial.",
          status: mapCurrentStepToStatus(currentStep, OnBoardingStep.WELCOME),
        },
        {
          title: "Contact Information",
          description:
            "Please enter your contact information to complete your profile.",
          status: mapCurrentStepToStatus(
            currentStep,
            OnBoardingStep.CONTACT_INFORMATION
          ),
        },
        {
          title: "Agent Information",
          description:
            "National Producer Number (NPN) and the states in which you will be selling.",
          status: mapCurrentStepToStatus(
            currentStep,
            OnBoardingStep.AGENT_INFORMATION
          ),
        },
        {
          title: "Photo",
          description: "Add a photo of yourself (optional).",
          status: mapCurrentStepToStatus(currentStep, OnBoardingStep.PHOTO),
        },
      ]}
      orientation={orientation}
    />
  );
};

export default OnBoardingProgress;
