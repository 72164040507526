import { MUTATION_RESULT_FIELDS } from "@/types/Fragments";
import { MutationResult } from "@/types/MutationResult";
import { gql } from "@apollo/client";
import { IBookmark, IBookmarkParam } from "../types/Bookmark";

export interface IAddBookmarkResponse {
  addBookmark: MutationResult;
}

export interface IAddBookmarkRequest {
  input: {
    pageType: string;
    name: string;
    params: IBookmarkParam[];
  };
}

export const ADD_BOOKMARK = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation AddBookmark($input: InputAddBookmark!) {
    addBookmark(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface IGetBookmarksResponse {
  getBookmarks: IBookmark[];
}

export interface IGetBookmarksRequest {}

export const GET_BOOKMARKS = gql`
  query GetBookmarks {
    getBookmarks {
      id
      userId
      pageType
      name
      index
      params {
        key
        value
      }
    }
  }
`;

export interface IGetBookmarkByIdResponse {
  getBookmarkById: IBookmark;
}

export interface IGetBookmarkByIdRequest {
  bookmarkId: string;
}

export const GET_BOOKMARK_BY_ID = gql`
  query GetBookmarkById($bookmarkId: String!) {
    getBookmarkById(bookmarkId: $bookmarkId) {
      id
      userId
      pageType
      name
      index
      params {
        key
        value
      }
    }
  }
`;

export interface IEditBookmarkResponse {
  editBookmark: MutationResult;
}

export interface IEditBookmarkRequest {
  input: {
    id: string;
    name: string;
  };
}

export const EDIT_BOOKMARK = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation EditBookmark($input: InputEditBookmark!) {
    editBookmark(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface IRemoveBookmarkResponse {
  removeBookmark: MutationResult;
}

export interface IRemoveBookmarkRequest {
  id: string;
}

export const REMOVE_BOOKMARK = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation RemoveBookmark($id: String!) {
    removeBookmark(id: $id) {
      ...MutationResultFields
    }
  }
`;

export interface IReorderBookmarkResponse {
  reorderBookmark: MutationResult;
}

export interface IReorderBookmarkRequest {
  input: {
    id: string;
    index: number;
  };
}

export const REORDER_BOOKMARK = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation ReorderBookmark($input: InputReorderBookmark!) {
    reorderBookmark(input: $input) {
      ...MutationResultFields
    }
  }
`;
