import React from "react";

import { getBookmarkIcon, getBookmarkPath } from "../../../constants/bookmarks";
import { IBookmark } from "../../../types/Bookmark";
import Link from "../Link";

interface IBookmarkItemProps {
  bookmark: IBookmark;
}

const BookmarkItem = ({ bookmark }: IBookmarkItemProps) => {
  const bookmarkIcon = React.createElement(getBookmarkIcon[bookmark.pageType], {
    width: 44,
    height: 52,
    className: "text-secondary-lightBlue",
  });

  const bookmarkPathFunc = getBookmarkPath[bookmark.pageType];
  if (!bookmarkPathFunc) return null;

  return (
    <Link href={bookmarkPathFunc(bookmark.params)!} key={bookmark.id}>
      <div className="flex flex-col justify-center items-center bg-white rounded-md border-2 mx-2 my-4 hover:shadow-lg hover:cursor-pointer h-28 break-all">
        {bookmarkIcon}
        <div className="flex">
          <span
            className="text-secondary-darkGrey text-sm font-sans line-clamp-1 m-2"
            title={bookmark.name}
          >
            {bookmark.name}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default BookmarkItem;
