import React, { ReactNode, useEffect, useRef, useState } from "react";
import { getUserId, getUserRole } from "@/services/auth";
import {
  getStoredBrokerDetails,
  setStoredBrokerDetails,
} from "@/services/user";
import { IBrokerDetails } from "@/types/User";
import { useContext } from "react";
import { usePermissionContext } from "./PermissionContext";
import { RoleGroup } from "@/types/RoleGroup";
import {
  GET_BROKER_DETAILS,
  IGetBrokerDetailsRequest,
  IGetBrokerDetailsResponse,
} from "@/queries/User";
import { useLazyQuery } from "@apollo/client";
import { useRouter } from "next/router";
import { setCookie } from "cookies-next";
import { navigation } from "@/layouts/HeadbarLayout";
import { paths } from "@/routing/paths";

interface IThemeContext {
  user: IBrokerDetails | undefined;
  setUser: React.Dispatch<React.SetStateAction<IBrokerDetails | undefined>>;
  brokerIdToImpersonate?: string;
  handleImpersonate: (organizationId?: string, brokerId?: string) => void;
  organizationToImpersonate?: string;
  setOrganizationToImpersonate: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  alerted: boolean;
  setAlerted: React.Dispatch<React.SetStateAction<boolean>>;
  redirectToAfterAlert?: string;
  setRedirectToAfterAlert: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const UserContext = React.createContext<IThemeContext>({
  user: undefined,
  setUser: () => {},
  brokerIdToImpersonate: undefined,
  handleImpersonate: () => {},
  organizationToImpersonate: undefined,
  setOrganizationToImpersonate: () => {},
  alerted: true,
  setAlerted: () => {},
  redirectToAfterAlert: undefined,
  setRedirectToAfterAlert: () => {},
});

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const firstRender = useRef(true);

  const { updatePermissions } = usePermissionContext();

  const [user, setUser] = useState<IBrokerDetails | undefined>(
    getStoredBrokerDetails()
  );
  const [organizationToImpersonate, setOrganizationToImpersonate] = useState<
    string | undefined
  >();

  const [brokerIdToImpersonate, setBrokerIdToImpersonate] = useState<
    string | undefined
  >();

  const [alerted, setAlerted] = useState<boolean>(true);
  const [redirectToAfterAlert, setRedirectToAfterAlert] = useState<
    string | undefined
  >();

  const handleImpersonate = (organizationId?: string, brokerId?: string) => {
    if (organizationId && brokerId) {
      setOrganizationToImpersonate(organizationId);
      setBrokerIdToImpersonate(brokerId);
      sessionStorage.setItem("brokerIdToImpersonate", brokerId);
      sessionStorage.setItem("organizationIdToImpersonate", organizationId);
      setCookie("impersonating", "true");
    } else {
      setOrganizationToImpersonate(undefined);
      setBrokerIdToImpersonate(undefined);
      setCookie("impersonating", "false");
      sessionStorage.removeItem("organizationIdToImpersonate");
      sessionStorage.removeItem("brokerIdToImpersonate");
      const currentNavItem = navigation
        .map((n) => n.elements?.find((e) => e.href === router.pathname))
        .find(Boolean);

      const requireImpersonation = currentNavItem?.requireImpersonation;

      if (requireImpersonation) router.push(paths.dashboard());
      else router.replace(router.asPath);
    }
  };

  const [getBrokerDetails] = useLazyQuery<
    IGetBrokerDetailsResponse,
    IGetBrokerDetailsRequest
  >(GET_BROKER_DETAILS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (redirectToAfterAlert && alerted && !!brokerIdToImpersonate) {
      router.push(redirectToAfterAlert);
      setRedirectToAfterAlert(undefined);
    }
  }, [redirectToAfterAlert, alerted]);

  useEffect(() => {
    const organizationIdToImpersonate = sessionStorage.getItem(
      "organizationIdToImpersonate"
    );
    const brokerIdToImpersonate = sessionStorage.getItem(
      "brokerIdToImpersonate"
    );
    if (brokerIdToImpersonate && organizationIdToImpersonate)
      handleImpersonate(organizationIdToImpersonate, brokerIdToImpersonate);
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    user && setStoredBrokerDetails(user);
  }, [user]);

  useEffect(() => {
    if (brokerIdToImpersonate) {
      getBrokerDetails({
        variables: {
          userId: brokerIdToImpersonate,
        },
      }).then((result) => {
        setUser(result.data?.getBrokerDetails);
      });
    }
  }, [JSON.stringify(brokerIdToImpersonate)]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        brokerIdToImpersonate,
        handleImpersonate,
        organizationToImpersonate,
        setOrganizationToImpersonate,
        alerted,
        setAlerted,
        redirectToAfterAlert,
        setRedirectToAfterAlert,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserContextProvider;
