import * as Icons from "@heroicons/react/24//outline";
import { ReactElement } from "react";
import {
  ArticlesView,
  BrokersDetails,
  ProspectView,
  ProspectsIndex,
  ApplicationMaterialsGallery,
  GroupMaterialsGallery,
  ProducerMaterialsGallery,
  paths,
  GroupsIndex,
  MyGroupsIndex,
  CustomerMaterialsGallery,
  AgentDocumentsIndex,
  GroupQuotesIndex,
  GroupQuotesCreate,
  IndividualIndex,
  PendingCasesIndex,
  MyBusinessIndex,
  MaterialsIndex,
  GroupRateSheetsCreate,
  IndividualRateSheetsCreate,
  IndividualRateCalculator,
} from "../routing/paths";

import { IBookmarkParam } from "../types/Bookmark";
import {
  articlesViewParamKeys,
  brokerDetailsParamKeys,
  prospectViewParamKeys,
  prospectsIndexParamKeys,
  groupMaterialsGalleryParamKeys,
  applicationMaterialsGalleryParamKeys,
  customerMaterialsGalleryParamKeys,
  producerMaterialsGalleryParamKeys,
  groupsIndexParamKeys,
  myGroupsIndexParamKeys,
  pendingCasesIndexParamKeys,
  agentDocumentsIndexParamKeys,
  materialsIndexParamKeys,
  myBusinessIndexParamKeys,
  individualIndexParamKeys,
  groupQuotesIndexParamKeys,
  groupQuotesCreateParamKeys,
  groupRateSheetCreateParamKeys,
  individualRateSheetCreateParamKeys,
  individualRateCalculatorParamKeys,
} from "./bookmarksParamKeys";

export enum PageType {
  MY_BUSINESS_INDEX = "MY_BUSINESS_INDEX",
  PROSPECT_VIEW = "PROSPECT_VIEW",
  PENDING_CASES_INDEX = "PENDING_CASES_INDEX",
  AGENT_DOCUMENTS_INDEX = "AGENT_DOCUMENTS_INDEX",

  MATERIALS_INDEX = "MATERIALS_INDEX",
  MATERIALS_CUSTOMER_GALLERY = "MATERIALS_CUSTOMER_GALLERY",
  MATERIALS_GROUP_GALLERY = "MATERIALS_GROUP_GALLERY",
  MATERIALS_PRODUCER_GALLERY = "MATERIALS_PRODUCER_GALLERY",
  MATERIALS_APPLICATION_GALLERY = "MATERIALS_APPLICATION_GALLERY",

  INDIVIDUAL_INDEX = "INDIVIDUAL_INDEX",
  SALES_ILLUSTRATIONS_INDEX = "SALES_ILLUSTRATIONS_INDEX",
  INDIVIDUAL_RATE_SHEETS_CREATE = "INDIVIDUAL_RATE_SHEETS_CREATE",
  INDIVIDUAL_RATE_CALCULATOR = "INDIVIDUAL_RATE_CALCULATOR",

  GROUPS_INDEX = "GROUPS_INDEX",
  MY_GROUPS_INDEX = "MY_GROUPS_INDEX",
  GROUP_QUOTES_INDEX = "GROUP_QUOTES_INDEX",
  GROUP_QUOTES_CREATE = "GROUP_QUOTES_CREATE",
  GROUP_RATE_SHEETS_CREATE = "GROUP_RATE_SHEETS_CREATE",

  ARTICLES_VIEW = "ARTICLES_VIEW",

  BROKER_DETAILS = "BROKER_DETAILS",

  PROSPECTS_INDEX = "PROSPECTS_INDEX",
}

export const getBookmarkPath: {
  [pageType in PageType]: (params: IBookmarkParam[]) => string | null;
} = {
  MY_BUSINESS_INDEX: (params) =>
    paths.business.index.apply(
      null,
      myBusinessIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<MyBusinessIndex>
    ),
  PROSPECTS_INDEX: (params) => {
    return paths.business.prospects.index.apply(
      null,
      prospectsIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<ProspectsIndex>
    );
  },
  PENDING_CASES_INDEX: (params) => {
    return paths.business.pendingCases.index.apply(
      null,
      pendingCasesIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<PendingCasesIndex>
    );
  },
  AGENT_DOCUMENTS_INDEX: (params) => {
    return paths.business.documents.index.apply(
      null,
      agentDocumentsIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<AgentDocumentsIndex>
    );
  },

  MATERIALS_INDEX: (params) =>
    paths.materials.index.apply(
      null,
      materialsIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<MaterialsIndex>
    ),
  MATERIALS_CUSTOMER_GALLERY: (params) => {
    return paths.materials.customer.gallery.apply(
      null,
      customerMaterialsGalleryParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<CustomerMaterialsGallery>
    );
  },
  MATERIALS_GROUP_GALLERY: (params) => {
    return paths.materials.group.gallery.apply(
      null,
      groupMaterialsGalleryParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<GroupMaterialsGallery>
    );
  },
  MATERIALS_PRODUCER_GALLERY: (params) => {
    return paths.materials.producer.gallery.apply(
      null,
      producerMaterialsGalleryParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<ProducerMaterialsGallery>
    );
  },
  MATERIALS_APPLICATION_GALLERY: (params) => {
    return paths.materials.application.gallery.apply(
      null,
      applicationMaterialsGalleryParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<ApplicationMaterialsGallery>
    );
  },

  INDIVIDUAL_INDEX: (params) => {
    return paths.individual.salesIllustrations.index.apply(
      null,
      individualIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<IndividualIndex>
    );
  },
  SALES_ILLUSTRATIONS_INDEX: (params) => {
    return paths.individual.salesIllustrations.index.apply(
      null,
      groupsIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<GroupsIndex>
    );
  },
  INDIVIDUAL_RATE_SHEETS_CREATE: (params) => {
    return paths.individual.individualRateSheets.create.apply(
      null,
      individualRateSheetCreateParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<IndividualRateSheetsCreate>
    );
  },
  INDIVIDUAL_RATE_CALCULATOR: (params) => {
    return paths.individual.individualRateCalculator.calculate.apply(
      null,
      individualRateCalculatorParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<IndividualRateCalculator>
    );
  },

  GROUPS_INDEX: (params) => {
    return paths.groups.index.apply(
      null,
      groupsIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<GroupsIndex>
    );
  },
  MY_GROUPS_INDEX: (params) => {
    return paths.groups.myGroups.index.apply(
      null,
      myGroupsIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<MyGroupsIndex>
    );
  },
  GROUP_QUOTES_INDEX: (params) => {
    return paths.groups.groupQuotes.index.apply(
      null,
      groupQuotesIndexParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<GroupQuotesIndex>
    );
  },
  GROUP_QUOTES_CREATE: (params) => {
    return paths.groups.groupQuotes.create.apply(
      null,
      groupQuotesCreateParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<GroupQuotesCreate>
    );
  },
  GROUP_RATE_SHEETS_CREATE: (params) => {
    return paths.groups.groupRateSheets.create.apply(
      null,
      groupRateSheetCreateParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<GroupRateSheetsCreate>
    );
  },

  ARTICLES_VIEW: (params) => {
    return paths.articles.view.apply(
      null,
      articlesViewParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<ArticlesView>
    );
  },

  BROKER_DETAILS: (params) => {
    return paths.brokers.details.apply(
      null,
      brokerDetailsParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<BrokersDetails>
    );
  },
  PROSPECT_VIEW: (params) => {
    return paths.business.prospects.view.apply(
      null,
      prospectViewParamKeys.map(
        (paramKey) => params.find((param) => param.key === paramKey)!.value
      ) as Parameters<ProspectView>
    );
  },
};

export const getBookmarkIcon: {
  [pageType in PageType]: (...args: any) => ReactElement;
} = {
  MY_BUSINESS_INDEX: Icons.BuildingOfficeIcon,
  PROSPECTS_INDEX: Icons.UserCircleIcon,
  PENDING_CASES_INDEX: Icons.DocumentDuplicateIcon,
  AGENT_DOCUMENTS_INDEX: Icons.DocumentTextIcon,

  MATERIALS_INDEX: Icons.DocumentDuplicateIcon,
  MATERIALS_CUSTOMER_GALLERY: Icons.DocumentDuplicateIcon,
  MATERIALS_GROUP_GALLERY: Icons.DocumentDuplicateIcon,
  MATERIALS_PRODUCER_GALLERY: Icons.DocumentDuplicateIcon,
  MATERIALS_APPLICATION_GALLERY: Icons.DocumentDuplicateIcon,

  INDIVIDUAL_INDEX: Icons.UserIcon,
  SALES_ILLUSTRATIONS_INDEX: Icons.PresentationChartBarIcon,
  INDIVIDUAL_RATE_SHEETS_CREATE: Icons.UserPlusIcon,
  INDIVIDUAL_RATE_CALCULATOR: Icons.UserPlusIcon,

  GROUPS_INDEX: Icons.UsersIcon,
  MY_GROUPS_INDEX: Icons.UserGroupIcon,
  GROUP_QUOTES_INDEX: Icons.UserGroupIcon,
  GROUP_QUOTES_CREATE: Icons.UserPlusIcon,
  GROUP_RATE_SHEETS_CREATE: Icons.UserPlusIcon,

  ARTICLES_VIEW: Icons.DocumentIcon,

  BROKER_DETAILS: Icons.UserIcon,
  PROSPECT_VIEW: Icons.UserCircleIcon,
};
