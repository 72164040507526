import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

export interface IRouteHistoryContext {
  backPathname?: string;
  setBackPathname?: Dispatch<SetStateAction<string | undefined>>;
}

const routeHistoryContextDefaultValues: IRouteHistoryContext = {};

const RouteHistoryContext = createContext<IRouteHistoryContext>(
  routeHistoryContextDefaultValues
);

export function useRouteHistory() {
  return useContext(RouteHistoryContext);
}

const RouteHistoryContextProvider = ({ children }: { children: ReactNode }) => {
  const [backPathname, setBackPathname] = useState<string | undefined>();

  return (
    <RouteHistoryContext.Provider value={{ backPathname, setBackPathname }}>
      {children}
    </RouteHistoryContext.Provider>
  );
};

export default RouteHistoryContextProvider;
