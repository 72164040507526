import { useRouter } from "next/router";
import React, { ReactNode, useState } from "react";
import { useContext } from "react";
import { OnBoardingStep } from "../components/OnBoarding/OnBoardingProgress";
import { paths } from "../routing/paths";

interface IOnBoardingStep {
  onBoardingStep: OnBoardingStep;
  setOnBoardingStep: React.Dispatch<React.SetStateAction<OnBoardingStep>>;
}

export const OnBoardingStepContext = React.createContext<IOnBoardingStep>({
  onBoardingStep: OnBoardingStep.WELCOME,
  setOnBoardingStep: () => {},
});

const OnBoardingStepProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const mapPathnameToStep = (pathname: string) => {
    switch (pathname) {
      case paths.onboarding.welcome():
        return OnBoardingStep.WELCOME;
      case paths.onboarding.contactInformation():
        return OnBoardingStep.CONTACT_INFORMATION;
      case paths.onboarding.agentInformation():
        return OnBoardingStep.AGENT_INFORMATION;
      case paths.onboarding.npnLookup():
        return OnBoardingStep.AGENT_INFORMATION;
      case paths.onboarding.photo():
        return OnBoardingStep.PHOTO;
      default:
        return OnBoardingStep.WELCOME;
    }
  };

  const [onBoardingStep, setOnBoardingStep] = useState<OnBoardingStep>(
    mapPathnameToStep(router.pathname)
  );

  return (
    <OnBoardingStepContext.Provider
      value={{
        onBoardingStep,
        setOnBoardingStep,
      }}
    >
      {children}
    </OnBoardingStepContext.Provider>
  );
};

export const useOnBoardingStep = () => useContext(OnBoardingStepContext);

export default OnBoardingStepProvider;
