import { isHomePage } from "@/routing/helpers";
import { paths } from "@/routing/paths";
import { useQuery } from "@apollo/client";
import {
  useMutationWithPolling,
  useOnClickOutside,
} from "@reframe-financial/chaplin";
import { motion } from "framer-motion";
import router from "next/router";
import React, { useEffect, useRef, useState } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { bookmarksCarouselResponsive } from "../../../constants/carousel";
import {
  EDIT_BOOKMARK,
  IEditBookmarkRequest,
  GET_BOOKMARKS,
  IGetBookmarksRequest,
  IGetBookmarksResponse,
  IReorderBookmarkRequest,
  IReorderBookmarkResponse,
  REORDER_BOOKMARK,
  IEditBookmarkResponse,
} from "../../../queries/Bookmark";
import { IBookmark } from "../../../types/Bookmark";
import BookmarkItem from "./BookmarkItem";
import BookmarkItemEditable from "./BookmarkItemEditable";
import BookmarkItemPlaceholder from "./BookmarkItemPlaceholder";

interface IBookmarkBarProps {}

const BookmarkBar = ({}: IBookmarkBarProps) => {
  const COLLAPSE_TOP_LIMIT = 32;
  const COLLAPSED_HEIGHT = 42;
  const EXPANDED_HEIGHT = 200;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!isHomePage());
  const [isExpansionFixed, setIsExpansionFixed] = useState<boolean>(
    isHomePage()
  );
  const [hovering, setHovering] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);

  const bookmarkBarRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<Carousel>(null);

  const [editBookmark] = useMutationWithPolling<
    IEditBookmarkResponse,
    IEditBookmarkRequest
  >(EDIT_BOOKMARK);

  const [reorderBookmark] = useMutationWithPolling<
    IReorderBookmarkResponse,
    IReorderBookmarkRequest
  >(REORDER_BOOKMARK);

  const [delayHandler, setDelayHandler] = useState<
    NodeJS.Timeout | undefined
  >();

  const { data, refetch } = useQuery<
    IGetBookmarksResponse,
    IGetBookmarksRequest
  >(GET_BOOKMARKS);

  const bookmarks = data?.getBookmarks;

  const handleOnHoverStart = () => {
    setHovering(true);
    setDelayHandler(
      setTimeout(() => {
        if (
          !isExpansionFixed ||
          document.documentElement.scrollTop >= COLLAPSE_TOP_LIMIT
        )
          setIsCollapsed(false);
      }, 250)
    );
  };

  const handleOnHoverEnd = () => {
    setHovering(false);
    clearTimeout(delayHandler);
    if (
      !editMode &&
      !touched &&
      (!isExpansionFixed ||
        document.documentElement.scrollTop >= COLLAPSE_TOP_LIMIT)
    )
      setIsCollapsed(true);
  };

  const handleOnTouchStart = () => {
    if (
      !isExpansionFixed ||
      document.documentElement.scrollTop >= COLLAPSE_TOP_LIMIT
    ) {
      setIsCollapsed(false);
      setTouched(true);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsExpansionFixed(
        isHomePage() &&
          document.documentElement.offsetHeight > // if document body height is bigger than the viewport plus the bar height, then allow collapsing
            window.innerHeight +
              (isCollapsed ? COLLAPSED_HEIGHT : EXPANDED_HEIGHT) &&
          document.documentElement.scrollTop < COLLAPSE_TOP_LIMIT
      );

      if (!hovering && !editMode && isExpansionFixed) {
        setIsCollapsed(
          document.documentElement.scrollTop >= COLLAPSE_TOP_LIMIT
        );
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [hovering, editMode, isCollapsed, isExpansionFixed]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setIsCollapsed(url !== paths.dashboard());
      setTimeout(() => {
        setIsExpansionFixed(
          url === paths.dashboard() &&
            document.documentElement.offsetHeight > // if document body height is bigger than the viewport plus the bar height, then allow collapsing
              window.innerHeight +
                (isCollapsed ? COLLAPSED_HEIGHT : EXPANDED_HEIGHT)
        );
      }, 1);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  const clickOutsideHandler = () => {
    setEditMode(false);
    if (document.documentElement.scrollTop >= COLLAPSE_TOP_LIMIT)
      setIsCollapsed(true);
  };

  useOnClickOutside(bookmarkBarRef, clickOutsideHandler);

  return (
    <motion.div
      className="top-20 w-full border-b-1 shadow-sm bg-white overflow-hidden"
      initial={{
        height: isCollapsed ? `${COLLAPSED_HEIGHT}px` : `${EXPANDED_HEIGHT}px`,
      }}
      animate={{
        height: isCollapsed ? `${COLLAPSED_HEIGHT}px` : `${EXPANDED_HEIGHT}px`,
      }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      onHoverStart={handleOnHoverStart}
      onHoverEnd={handleOnHoverEnd}
      onTouchStart={handleOnTouchStart}
      ref={bookmarkBarRef}
    >
      <div className="m-3">
        <motion.div>
          <div className="flex justify-between">
            <p className="mx-2 text-sm leading-5 font-interMedium tracking-wide uppercase text-gray-500">
              Bookmarks
            </p>
            <p
              className="mx-2 text-sm font-interRegular text-gray-500 underline cursor-pointer"
              onClick={() => {
                setIsCollapsed(false);
                setEditMode((prevEditMode) => !prevEditMode);
              }}
            >
              {bookmarks &&
                bookmarks?.length > 0 &&
                (editMode ? "Done" : "Edit Bookmarks")}
            </p>
          </div>
          <div className="flex flex-col items-center">
            {bookmarks && (
              <Carousel
                ref={carouselRef}
                responsive={bookmarksCarouselResponsive}
                swipeable={!editMode}
                draggable={!editMode}
                renderArrowsWhenDisabled
              >
                {bookmarks?.map((bookmark: IBookmark) =>
                  editMode ? (
                    <BookmarkItemEditable
                      bookmark={bookmark}
                      editBookmark={editBookmark}
                      reorderBookmark={reorderBookmark}
                      refetchBookmarks={refetch}
                      key={bookmark.id}
                      carouselRef={carouselRef}
                    />
                  ) : (
                    <BookmarkItem bookmark={bookmark} key={bookmark.id} />
                  )
                )}
                <BookmarkItemPlaceholder />
              </Carousel>
            )}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default BookmarkBar;
