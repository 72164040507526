import React, {
  useRef,
  useState,
  KeyboardEvent,
  MutableRefObject,
} from "react";

import { getBookmarkIcon, getBookmarkPath } from "../../../constants/bookmarks";
import { IBookmark } from "../../../types/Bookmark";
import {
  MutationResult,
  useMutationWithPolling,
  useOnClickOutside,
} from "@reframe-financial/chaplin";

import {
  TrashIcon,
  PencilIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  IEditBookmarkRequest,
  IEditBookmarkResponse,
  IRemoveBookmarkRequest,
  IRemoveBookmarkResponse,
  IReorderBookmarkRequest,
  IReorderBookmarkResponse,
  REMOVE_BOOKMARK,
} from "../../../queries/Bookmark";
import {
  ApolloCache,
  ApolloQueryResult,
  DefaultContext,
  MutationHookOptions,
  OperationVariables,
} from "@apollo/client";
import { useDrag, useDrop } from "react-dnd";
import { Identifier } from "dnd-core";
import { motion } from "framer-motion";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";
import { Process } from "@/types/Process";

interface IBookmarkItemProps {
  bookmark: IBookmark;
  carouselRef: MutableRefObject<Carousel | null>;
  editBookmark: (
    options?: MutationHookOptions<
      IEditBookmarkResponse,
      IEditBookmarkRequest,
      DefaultContext,
      ApolloCache<any>
    >
  ) => Promise<Process | MutationResult>;
  refetchBookmarks: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<any>>;
  reorderBookmark: (
    options?: MutationHookOptions<
      IReorderBookmarkResponse,
      IReorderBookmarkRequest,
      DefaultContext,
      ApolloCache<any>
    >
  ) => Promise<MutationResult | Process>;
}

interface IDragItem {
  index: number;
  id: string;
  type: string;
}

const BookmarkItemEditable = ({
  bookmark,
  carouselRef,
  editBookmark,
  refetchBookmarks,
  reorderBookmark,
}: IBookmarkItemProps) => {
  const REORDERING_SCALE = 1.1;
  const CAROUSEL_OFFSET = 80;

  const [editName, setEditName] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(bookmark.name);
  const [reordering, setReordering] = useState<boolean>(false);

  const editedBookmarkRef = useRef<HTMLDivElement>(null);

  const [removeBookmark] = useMutationWithPolling<
    IRemoveBookmarkResponse,
    IRemoveBookmarkRequest
  >(REMOVE_BOOKMARK);

  const [{ handlerId, doneHovering }, drop] = useDrop<
    IDragItem,
    void,
    {
      handlerId: Identifier | null;
      doneHovering: boolean;
    }
  >({
    accept: "BOOKMARK_ITEM",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        doneHovering: monitor.isOver(),
      };
    },
    drop(item: IDragItem) {
      if (!editedBookmarkRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = bookmark.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      reorderBookmark({
        variables: { input: { id: item.id, index: bookmark.index } },
      })
        .then(() => refetchBookmarks())
        .catch((err) => toast.error(err));

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    hover: (item, monitor) => {
      window.innerWidth;
      if (
        monitor.getClientOffset()?.x ??
        0 > window.innerWidth - CAROUSEL_OFFSET
      )
        carouselRef.current?.next(0);
      if (monitor.getClientOffset()?.x ?? 0 < CAROUSEL_OFFSET)
        carouselRef.current?.previous(0);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "BOOKMARK_ITEM",
    item: () => {
      return { id: bookmark.id, index: bookmark.index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(editedBookmarkRef));

  useEffect(() => {
    setReordering(doneHovering);
  }, [doneHovering]);

  const bookmarkIcon = React.createElement(getBookmarkIcon[bookmark.pageType], {
    width: 44,
    height: 52,
    className: "text-secondary-lightBlue",
  });

  const clickOutsideHandler = () => {
    editNameHandler();
  };

  const editNameHandler = () => {
    if (newName !== bookmark.name && newName !== "")
      editBookmark({
        variables: { input: { id: bookmark.id, name: newName } },
      })
        .then(() => refetchBookmarks())
        .catch(() => toast.error("Could not edit bookmark"));

    setEditName(false);
  };

  const cancelEditNameHandler = () => {
    setEditName(false);
    setNewName(bookmark.name);
  };

  const onPressEnterHandler = (e: KeyboardEvent) => {
    if (e.key === "Enter") editNameHandler();
    if (e.key === "Escape") cancelEditNameHandler();
  };

  useOnClickOutside(editedBookmarkRef, clickOutsideHandler);

  const bookmarkPathFunc = getBookmarkPath[bookmark.pageType];
  if (!bookmarkPathFunc) return null;

  return (
    <motion.div
      className="flex flex-col justify-center items-center bg-white rounded-md border-2 mx-2 my-4 h-28"
      style={{ opacity }}
      ref={editedBookmarkRef}
      data-handler-id={handlerId}
      animate={{ scale: reordering ? REORDERING_SCALE : 1 }}
    >
      <div
        className="self-start -mt-[2px] -ml-[2px] font-interRegular bg-gray-200 text-gray-800 text-xs mr-2 px-2.5 py-0.5
                   rounded-br-md rounded-tl-md h-10 inline-flex items-center hover:shadow-md hover:cursor-pointer"
        onClick={() =>
          removeBookmark({ variables: { id: bookmark.id } })
            .then(() => refetchBookmarks())
            .catch(() => toast.error("Could not remove the bookmark"))
        }
      >
        <TrashIcon width={16} />
        <span className="ml-2">Delete</span>
      </div>
      {bookmarkIcon}
      <div className="flex pl-3 pr-3 pb-3 break-all">
        {editName ? (
          <input
            className="appearance-none bg-transparent border-b-1 border-gray-500 w-full text-gray-700 text-sm py-1 px-2 leading-tight focus:outline-none"
            type="text"
            name="city"
            required
            value={newName}
            autoFocus
            onChange={(e) => setNewName(e.target.value)}
            onKeyDown={onPressEnterHandler}
          />
        ) : (
          <span
            className="text-secondary-darkGrey font-medium text-sm font-sans line-clamp-1 mt-2"
            onClick={() => setEditName(true)}
            title={bookmark.name}
          >
            {bookmark.name}
          </span>
        )}
        {editName ? (
          <>
            <CheckIcon
              width={24}
              className="text-secondary-lightBlue ml-2 shrink-0 hover:cursor-pointer"
              onClick={editNameHandler}
            />
          </>
        ) : (
          <PencilIcon
            width={16}
            className="text-black ml-2 shrink-0 hover:cursor-pointer mt-2"
            onClick={() => setEditName(true)}
          />
        )}
      </div>
    </motion.div>
  );
};

export default BookmarkItemEditable;
