import React, { ReactElement } from "react";

interface IGenericModalContent {
  title: string;

  children?: ReactElement;
}

const GenericModalContent = ({ title, children }: IGenericModalContent) => {
  return (
    <div className="flex flex-col flex-wrap">
      <div className="flex flex-1 mb-8 justify-center sm:justify-start">
        <h1 className="font-greycliffMedium leading-6 text-2xl text-primary-blue">
          {title}
        </h1>
      </div>
      <div className="flex w-full justify-center">{children}</div>
    </div>
  );
};

export default GenericModalContent;
