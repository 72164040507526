import { State } from "../constants/states";
import { IOrganization } from "./Organization";

export interface IUser {
  name: string;
  accountEnabled: boolean;
}

export enum BrokerRole {
  BACK_OFFICE_STAFF = "BACK_OFFICE_STAFF",
  OWNER = "OWNER",
  MANAGER = "MANAGER",
  MEMBER = "MEMBER",
}

export const brokerRoleVerboseNames = {
  BACK_OFFICE_STAFF: "Back Office Staff",
  OWNER: "Owner",
  MANAGER: "Manager",
  MEMBER: "Member",
};

export enum BrokerLicenseColor {
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  RED = "RED",
  ORANGE = "ORANGE",
}

export enum LicenseStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EXPIRED = "EXPIRED",
  UNKNOWN = "UNKNOWN",
}

export interface IBrokerLicenseStatusDetails {
  status: LicenseStatus;
  color: BrokerLicenseColor;
}

export enum BrokerCEStatusIndicator {
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  RED = "RED",
  ORANGE = "ORANGE",
}

export interface IBrokerLicenseStatus {
  expirationDate?: string;
  state: State;
  status: IBrokerLicenseStatusDetails;
  details: IBrokerLicenseDetails[];
}
export interface ILicenseAppointment {
  company: string;
  status: string;
  reasonDate: string;
  renewalDate: string;
}

export interface IBrokerLicenseDetails {
  loa: string;
  status: string;
  issueDate: string;
}

export interface IBrokerClearCertStatus {
  state: State;
  status: string;
  reason: string;
  throughDate: string;
  statusIndicator: BrokerCEStatusIndicator;
}

export interface IBrokerDetails {
  brokerId: string;
  role: BrokerRole;
  city: string;
  givenName: string;
  mail: string;
  mobilePhone: string;
  postalCode: string;
  state: string;
  streetAddress: string;
  streetAddress2: string;
  surName: string;
  accountEnabled: boolean;
  organizations: IOrganization[];
  photoId: string;
  npn: string;
  products: string[];
  isOnboardingCompleted?: boolean;
  sellingStates?: string[];
  licenseStatuses?: IBrokerLicenseStatus[];
  clearCertStatuses?: IBrokerClearCertStatus[];
  licenseAppointments?: ILicenseAppointment[];
}

export interface IUserProfilePhoto {
  file: File;
}

export interface IChangePassword {
  currentPassword: string;
  newPassword: string;
}

export interface ISignUpData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  npn: string;
}

export interface IMsUser {
  id: string;
  name: string;
  email: string;
  accountEnabled: boolean;
  role: BrokerRole;
}
