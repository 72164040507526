import { useState, useEffect, ReactElement } from "react";
import { useRouter } from "next/router";
import { getUserRole, isLoggedIn } from "../../services/auth";
import { publicRoutes } from "../../constants/publicRoutes";
import { paths } from "../../routing/paths";
import { RoleGroup } from "@/types/RoleGroup";

interface IRouteGuardProps {
  children: ReactElement;
}

function RouteGuard({ children }: IRouteGuardProps) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // on initial load - run auth check
    if (!router.isReady) return;

    authCheck(router.asPath);

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    router.events.on("routeChangeStart", hideContent);

    // on route change complete - run auth check
    router.events.on("routeChangeComplete", authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off("routeChangeStart", hideContent);
      router.events.off("routeChangeComplete", authCheck);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  function authCheck(nextUrl: string) {
    const url = new URL(nextUrl, window.location.origin);
    const { pathname } = url;

    // redirect to login page if accessing a private page and not logged in
    if (
      !isLoggedIn() &&
      !publicRoutes.filter((route) => pathname.match(route)).length
    ) {
      setAuthorized(false);
      router.push({
        pathname: paths.signin(),
        ...(pathname !== "/401" && { query: { redirectTo: pathname } }),
      });
    } else if (publicRoutes.includes(pathname)) {
      setAuthorized(true);
    } else {
      try {
        const role = getUserRole();
        if (
          !pathname.includes("admin") &&
          role === RoleGroup.Admin &&
          pathname !== "/"
        ) {
          setAuthorized(true);
          router.push("/admin/organizations");
        } else if (pathname.includes("admin") && role !== RoleGroup.Admin) {
          router.push("/");
          setAuthorized(true);
        } else {
          setAuthorized(true);
        }
      } catch (error) {
        console.error(error);
        setAuthorized(false);
        router.push(paths.signin());
      }
    }
  }

  return authorized ? children : null;
}

export default RouteGuard;
