const queryParamsRegExp = /\??.*/;

const concatenateRegExps = (r1: RegExp, r2: RegExp) => {
  const flags = (r1.flags + r2.flags)
    .split("")
    .sort()
    .join("")
    .replace(/(.)(?=.*\1)/g, "");
  return new RegExp(r1.source + r2.source, flags);
};

export const publicRoutes = [
  concatenateRegExps(/\/signin/, queryParamsRegExp),
  concatenateRegExps(/\/invite/, queryParamsRegExp),
  "/signup",
  "/signin",
  "/verify",
  "/passwordreset",
];
