import classnames from "classnames";
import { MouseEventHandler, useEffect } from "react";
import { Loader } from "@reframe-financial/chaplin";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { ClipLoader } from "react-spinners";

interface IReusableButtonProps {
  text?: string;
  buttonStyles?: string; //this prop must be passed as tailwind class names
  textStyles?: string; //this prop must be passed as tailwind class names
  iconComponent?: React.ReactNode;
  isIconOnLeft?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  [x: string]: any;
  type?: "button" | "submit" | "reset" | undefined;
  showLoader?: boolean;
  loaderSize?: number;
  loaderColor?: string;
  loaderIconClassname?: string;
  dataCy?: string;
}

export function ReusableButton({
  text,
  buttonStyles,
  textStyles,
  iconComponent,
  isIconOnLeft,
  disabled,
  onClick,
  type,
  showLoader,
  loaderSize,
  loaderColor,
  loaderIconClassname,
  dataCy,
  ...props
}: IReusableButtonProps) {
  const IconComponent = iconComponent ? (
    <div className={classnames({ invisible: showLoader })}>{iconComponent}</div>
  ) : undefined;

  return (
    <button
      {...props}
      className={classNames(buttonStyles, "relative", {
        "disabled:opacity-40 disabled:cursor-not-allowed": disabled,
      })}
      onClick={onClick}
      disabled={disabled}
      type={type ?? "button"}
      data-cy={dataCy}
    >
      {showLoader && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
          <Loader
            icon={<ClipLoader className={loaderIconClassname} size={20} />}
            displayTakingLongerMsg={false}
          />
        </div>
      )}
      <div className="flex items-center justify-center">
        {!isIconOnLeft && (
          <span className={classnames(textStyles, { invisible: showLoader })}>
            {IconComponent}
          </span>
        )}
        {text && (
          <span className={classnames(textStyles, { invisible: showLoader })}>
            {text}
          </span>
        )}
        {isIconOnLeft && (
          <span className={classnames(textStyles, { invisible: showLoader })}>
            {IconComponent}
          </span>
        )}
      </div>
    </button>
  );
}

const ReusableButtonBlue = ({
  buttonStyles,
  textStyles,
  ...props
}: IReusableButtonProps) => {
  return (
    <ReusableButton
      buttonStyles={twMerge(
        `h-8 p-2 border rounded cursor-pointer w-full sm:w-auto flex justify-center items-center disabled:opacity-40 disabled:cursor-default bg-secondary-lightBlue`,
        buttonStyles
      )}
      textStyles={twMerge(
        `text-white whitespace-nowrap font-interLight`,
        textStyles
      )}
      {...props}
    />
  );
};

const ReusableButtonWhite = ({
  buttonStyles,
  textStyles,
  ...props
}: IReusableButtonProps) => {
  return (
    <ReusableButton
      textStyles={twMerge(
        `text-sm leading-5 font-normal font-interMedium`,
        textStyles
      )}
      buttonStyles={twMerge(
        `h-8 p-2 border rounded cursor-pointer w-full sm:w-auto border-1 border-black-800 flex justify-center items-center disabled:opacity-40 disabled:cursor-default`,
        buttonStyles
      )}
      {...props}
    />
  );
};

const ReusableButtonGray = ({
  buttonStyles,
  textStyles,
  ...props
}: IReusableButtonProps) => {
  return (
    <ReusableButton
      textStyles={twMerge(
        `text-sm leading-5 font-normal font-interMedium text-white`,
        textStyles
      )}
      buttonStyles={twMerge(`px-4 py-2 bg-gray-400 rounded-md`, buttonStyles)}
      {...props}
    />
  );
};

ReusableButton.Blue = ReusableButtonBlue;

ReusableButton.White = ReusableButtonWhite;

ReusableButton.Gray = ReusableButtonGray;

export default ReusableButton;
