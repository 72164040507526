import React, { MouseEventHandler, useRef } from "react";
import {
  GET_BROKERS,
  GET_ORGANIZATIONS_BY_BROKER,
  IGetBrokersRequest,
  IGetBrokersResponse,
  IGetOrganizationsByBrokerRequest,
} from "@/queries/Organization";

import { Fragment, ReactElement, SVGProps, useEffect, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  BookmarkIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  Bars3Icon,
  ArrowPathIcon,
  ShieldCheckIcon,
  LifebuoyIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { EyeIcon, PencilIcon } from "@heroicons/react/24/solid";
import Logo from "../assets/images/reframe-logo-letters-dark.svg";
import LogoLetter from "../assets/images/reframe-logo.svg";
import Image from "next/image";
import { useOnClickOutside } from "@reframe-financial/chaplin";
import Link from "../components/shared/Link";
import { useRouter } from "next/router";
import BookmarkBar from "../components/shared/Bookmarks/BookmarkBar";
import { paths } from "../routing/paths";
import {
  signOut,
  getUserId,
  getUserName,
  getBrokerRole,
} from "../services/auth";
import { motion } from "framer-motion";
import { useUserContext } from "@/context/UserContext";
import { downloadUserPhoto } from "@/services/user";
import { ReusableModal } from "@reframe-financial/chaplin";
import GenericModalContent from "@/components/shared/GenericModalContent";
import SelectOrganizationAndBrokers from "@/components/shared/SelectOrganizationAndBrokers";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { IOption } from "@/types/Option";
import { Permission } from "@/constants/permission";
import PermissionsGuard from "@/components/shared/PermissionsGuard";
import { BrokerRole } from "@/types/User";
import { IOrganization } from "@/types/Organization";
import ViewingAsAlert from "@/components/ViewingAs/ViewingAsAlert";
import ViewingAsSelect from "@/components/ViewingAs/ViewingAsSelect";
import ViewingAsEnable from "@/components/ViewingAs/ViewingAsEnable";

interface INavItemElement {
  name: string;
  href: string;
  enabled: boolean;
  matchRegex: RegExp;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  permission?: Permission;
  requireImpersonation?: boolean;
}

interface INavItem {
  name: string;
  description: string;
  href?: string;
  matchRegex: RegExp;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  enabled: boolean;
  elements?: INavItemElement[];
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface IHeadbarProps {
  children: ReactElement;
}
export const navigation: INavItem[] = [
  {
    name: "Home",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: paths.dashboard(),
    matchRegex: new RegExp("/dashboard"),
    icon: ChartBarIcon,
    enabled: true,
  },
  {
    name: "My Business",
    description: "Speak directly to your customers in a more meaningful way.",
    href: paths.business.index(),
    matchRegex: new RegExp("/my-business"),
    icon: CursorArrowRaysIcon,
    enabled: true,
    elements: [
      {
        name: "New Application",
        href: paths.business.applications.create(),
        enabled: true,
        matchRegex: new RegExp("/my-business/applications/create"),
        requireImpersonation: false,
        permission: Permission.CREATE_A_NEW_APPLICATION,
      },
      {
        name: "Prospects",
        href: paths.business.prospects.index(),
        enabled: true,
        matchRegex: new RegExp("/my-business/prospects"),
        requireImpersonation: true,
      },
      {
        name: "Pending Cases",
        href: paths.business.pendingCases.index(),
        enabled: true,
        matchRegex: new RegExp("/my-business/pending-cases"),
        requireImpersonation: true,
      },
      {
        name: "Documents",
        href: paths.business.documents.index(),
        enabled: true,
        matchRegex: new RegExp("/my-business/documents"),
        requireImpersonation: false,
      },
    ],
  },
  {
    name: "Materials & Literature",
    description: "Your consumers' data will be safe and secure.",
    href: paths.materials.index(),
    matchRegex: new RegExp("/materials"),
    icon: ShieldCheckIcon,
    enabled: true,
    elements: [
      {
        name: "For Clients",
        href: paths.materials.customer.gallery("-", "-"),
        enabled: true,
        matchRegex: new RegExp("/materials/customer/"),
        requireImpersonation: false,
      },
      {
        name: "For Groups",
        href: paths.materials.group.gallery("-"),
        enabled: true,
        matchRegex: new RegExp("/materials/group/"),
        requireImpersonation: false,
      },
      {
        name: "Producer Materials",
        href: paths.materials.producer.gallery("-"),
        enabled: true,
        matchRegex: new RegExp("/materials/producer/"),
        requireImpersonation: false,
      },
      {
        name: "Application & Underwriting",
        href: paths.materials.application.gallery("-"),
        enabled: true,
        matchRegex: new RegExp("/materials/application/product/"),
        requireImpersonation: false,
      },
    ],
  },
  {
    name: "Individual",
    description: "Individual",
    href: paths.individual.index(),
    matchRegex: new RegExp("/individual"),
    icon: CursorArrowRaysIcon,
    enabled: true,
    elements: [
      {
        name: "Sales Illustration",
        href: paths.individual.salesIllustrations.index(),
        enabled: true,
        matchRegex: new RegExp("/individual/sales-illustrations"),
        permission: Permission.CREATE_A_SALES_ILLUSTRATION,
        requireImpersonation: false,
      },
      {
        name: "Rate Sheet Request",
        href: paths.individual.individualRateSheets.create(),
        enabled: true,
        matchRegex: new RegExp("/individual/individual-rate-sheets/create"),
        permission: Permission.CREATE_INDIVIDUAL_RATE_SHEET,
        requireImpersonation: false,
      },
      {
        name: "Rate Calculator",
        href: paths.individual.individualRateCalculator.calculate(),
        enabled: true,
        matchRegex: new RegExp("/individual/individual-rate/calculate"),
        permission: Permission.INDIVIDUAL_RATE_CALCULATOR,
        requireImpersonation: false,
      },
    ],
  },
  {
    name: "Groups",
    description: "Groups",
    href: paths.groups.index(),
    matchRegex: new RegExp("/groups"),
    icon: CursorArrowRaysIcon,
    enabled: true,
    elements: [
      {
        name: "My Groups",
        href: paths.groups.myGroups.index(),
        enabled: true,
        matchRegex: new RegExp("/groups/my-groups"),
        permission: Permission.CREATE_GROUP_QUOTE,
        requireImpersonation: true,
      },
      {
        name: "Group Quotes",
        href: paths.groups.groupQuotes.create(),
        enabled: true,
        matchRegex: new RegExp("/groups/group-quotes/create"),
        permission: Permission.CREATE_GROUP_QUOTE,
        requireImpersonation: true,
      },
      {
        name: "Group Rate Sheet",
        href: paths.groups.groupRateSheets.create(),
        enabled: true,
        matchRegex: new RegExp("/groups/group-rate-sheets/create"),
        permission: Permission.CREATE_GROUP_RATE_SHEET,
        requireImpersonation: true,
      },
      {
        name: "Group Approval Request",
        href: paths.groups.groupApprovalRequests.add(),
        enabled: true,
        matchRegex: new RegExp("/groups/my-groups/add/"),
        permission: Permission.ADD_GROUP_REQUEST,
        requireImpersonation: true,
      },
    ],
  },
  {
    name: "Articles",
    description: "Articles description...",
    href: paths.brokers.articles(),
    matchRegex: new RegExp("/articles"),
    icon: ArrowPathIcon,
    enabled: true,
  },
];

function Headbar({ children }: IHeadbarProps) {
  const router = useRouter();

  const {
    brokerIdToImpersonate,
    handleImpersonate,
    organizationToImpersonate,
    setOrganizationToImpersonate,
    user,
    alerted,
    setAlerted,
    setRedirectToAfterAlert,
  } = useUserContext();

  const [viewAsModalShown, setViewAsModalShown] = useState(false);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoto, setUserPhoto] = useState<string>();

  const [isDesktop, setDesktop] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState<INavItem | null>(null);
  const [hoveredNavItem, setHoveredNavItem] = useState<INavItem | null>(null);
  const [selectedNavItemElement, setSelectedNavItemElement] =
    useState<INavItemElement | null>(null);
  const { asPath, push } = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const [breadCrumbItem, setBreadCrumbItem] = useState<INavItem | null>(null);

  const toggleDropdown = () => {
    setClickingDropdownButton(true);
    if (selectedNavItem?.name !== hoveredNavItem?.name && isOpen) {
      setSelectedNavItem(hoveredNavItem);
    } else setIsOpen(!isOpen);
  };

  const [isMobileOpen, setMobileIsOpen] = useState(false);

  const toggleMobileDropdown = () => {
    if (selectedNavItem?.name !== hoveredNavItem?.name && isMobileOpen) {
      setSelectedNavItem(hoveredNavItem);
    } else setMobileIsOpen(!isMobileOpen);
  };

  const [getBrokers, { data: brokersData }] = useLazyQuery<
    IGetBrokersResponse,
    IGetBrokersRequest
  >(GET_BROKERS);

  const [getOrganizations, { data: organizationsData, refetch }] = useLazyQuery<
    { getOrganizationsByBrokerId: IOrganization[] },
    IGetOrganizationsByBrokerRequest
  >(GET_ORGANIZATIONS_BY_BROKER, {
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const _userId = getUserId();
    setUserId(_userId);
    if (_userId) {
      getOrganizations({
        variables: {
          id: _userId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  useEffect(() => {
    const downloadPicture = async () => {
      if (user?.photoId)
        downloadUserPhoto(user.photoId).then((data) => setUserPhoto(data));
    };
    downloadPicture();
  }, [user, user?.photoId]);

  const handleSignOut = () => {
    signOut();
    clearImpersonatedBrokersId();
    push(paths.signin());
  };

  const clearImpersonatedBrokersId = () => {
    setOrganizationToImpersonate(undefined);
    handleImpersonate(undefined, undefined);
  };

  const resources = [
    {
      name: "Edit Profile",
      description:
        "Get all of your questions answered in our forums or contact support.",
      onClick: () => {
        push(paths.brokers.details());
      },
      icon: LifebuoyIcon,
      dataCy: "headbar-edit-profile-button",
    },
    {
      name: "My Organization",
      description:
        "See what meet-ups and other events we might be planning near you.",
      onClick: () => {
        push(paths.brokers.organization(userId));
      },
      icon: CalendarIcon,
      dataCy: "headbar-my-organization-button",
    },
    {
      name: "Sign Out",
      description:
        "See what meet-ups and other events we might be planning near you.",
      icon: CalendarIcon,
      onClick: handleSignOut,
      dataCy: "headbar-sign-out-button",
    },
  ];

  const ContextMenu: any = (
    <Menu as="div" className="relative inline-block text-left">
      <div data-cy="headbar-options">
        <Menu.Button className="flex items-center  relative text-gray-300 hover:text-gray-600">
          <EllipsisVerticalIcon
            className="flex-shrink-0 h-6 w-6 text-gray-300"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            {resources.map(({ name, onClick, dataCy }) => {
              const baseComponent = (
                <Menu.Item key={name}>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm w-full text-left"
                      )}
                      onClick={onClick}
                      data-cy={dataCy}
                    >
                      {name}
                    </button>
                  )}
                </Menu.Item>
              );

              // <PermissionsGuard
              //     key={name}
              //     permission={(permissionList, role) =>
              //       role === BrokerRole.BACK_OFFICE_STAFF ||
              //       role === BrokerRole.OWNER ||
              //       permissionList.includes(Permission.VIEWING_AS)
              //     }
              //   >
              //     {baseComponent}
              //   </PermissionsGuard>

              return baseComponent;
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  useEffect(() => {
    setUserName(getUserName());

    if (window.outerWidth > 1300) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }

    const updateMedia = () => {
      if (window.outerWidth > 1300) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    setSelectedNavItem(() => {
      const navItem = navigation.find((navItem) =>
        navItem.matchRegex.test(asPath)
      );
      const navItemElement = navigation.find((navItem) => {
        const navItemElem = navItem.elements?.find((elem) =>
          elem.matchRegex.test(asPath)
        );
        setSelectedNavItemElement(navItemElem ?? null);
        return navItemElem !== undefined;
      });
      if (!navItemElement) setSelectedNavItemElement(null);
      return navItem ?? null;
    });
  }, [navigation, asPath]);

  const variants = {
    show: { height: "64px", opacity: 1 },
    hide: { height: "0px", opacity: 0 },
  };

  const dropdownClick = useRef<HTMLDivElement | null>(null);

  const clickOutsidehandler = () => {
    if (dropdownClick.current && (!clickingDropdownButton || !hoveredNavItem)) {
      setIsOpen(false);
    }
    setClickingDropdownButton(false);
  };

  useOnClickOutside(dropdownClick, clickOutsidehandler);
  const [clickingDropdownButton, setClickingDropdownButton] = useState(false);

  return (
    <>
      <div
        className="sticky top-0 z-50 bg-white"
        onMouseLeave={() => setHoveredNavItem(null)}
      >
        <Popover>
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <div className="h-20 flex justify-between items-end border-b-2 border-gray-100 z-50 bg-white">
              <div className="flex self-center justify-start">
                <Link href={paths.dashboard()}>
                  <a>
                    <span className="sr-only">Workflow</span>
                    {isDesktop ? <Logo /> : <LogoLetter />}
                  </a>
                </Link>
              </div>
              <div className="self-center -mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-12">
                {navigation.map((item) =>
                  item.enabled ? (
                    item.href ? (
                      <div
                        key={item.name}
                        className="flex"
                        onMouseLeave={() => setHoveredNavItem(null)}
                      >
                        <div className="flex">
                          <Link
                            href={item.href}
                            key={item.name}
                            className="pr-2"
                          >
                            <a
                              className={classNames(
                                item.matchRegex.test(asPath)
                                  ? "border-b-secondary-lightBlue pb-6 border-b-3 text-gray-900"
                                  : "text-gray-500 pb-6",
                                hoveredNavItem?.name === item.name &&
                                  !item.matchRegex.test(asPath)
                                  ? `border-b-gray-200 pb-6 border-b-3 duration-200`
                                  : "",
                                "text-sm font-medium leading-5 hover:text-gray-900 line-clamp-1"
                              )}
                              onClick={(e) => {
                                setIsOpen(false);
                                setBreadCrumbItem(item);
                              }}
                              onMouseEnter={(e) => {
                                const foundHoveredNavItem = navigation.find(
                                  (navItem) =>
                                    navItem.href === e.currentTarget.pathname
                                );
                                if (foundHoveredNavItem)
                                  setHoveredNavItem(foundHoveredNavItem);
                              }}
                            >
                              {item.name}
                            </a>
                          </Link>
                          {isOpen && selectedNavItem?.name === item.name && (
                            <motion.div
                              className="origin-top-right absolute w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 mt-8"
                              initial={{
                                opacity: 0,
                              }}
                              animate={{
                                opacity: 1,
                              }}
                              exit={{
                                opacity: 0,
                              }}
                            >
                              <div
                                className="bottom-10 z-200"
                                ref={dropdownClick}
                              >
                                {item?.elements?.map((elem) => {
                                  const baseElement = elem.enabled ? (
                                    <Link href={elem.href} key={elem.name}>
                                      <a
                                        className={classNames(
                                          "text-gray-500 p-2.5 text-sm font-medium leading-5 transition duration-300 hover:text-gray-900 hover:bg-blue-100 line-clamp-2",
                                          elem.name ===
                                            selectedNavItemElement?.name
                                            ? "bg-gray-100"
                                            : ""
                                        )}
                                        onClick={(e) => {
                                          setIsOpen(false);
                                          if (
                                            getBrokerRole() ===
                                              BrokerRole.BACK_OFFICE_STAFF &&
                                            elem.requireImpersonation &&
                                            !brokerIdToImpersonate
                                          ) {
                                            e.preventDefault();
                                            setAlerted(false);
                                            setRedirectToAfterAlert(elem.href);
                                          } else {
                                            setBreadCrumbItem(item);
                                          }
                                        }}
                                      >
                                        {elem.name}
                                      </a>
                                    </Link>
                                  ) : (
                                    <motion.p
                                      className="ml-8 text-sm p-2 font-medium leading-5 line-clamp-1 text-gray-300"
                                      key={elem.name}
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                    >
                                      {elem.name}
                                    </motion.p>
                                  );

                                  return elem.permission ? (
                                    <PermissionsGuard
                                      permission={elem.permission}
                                    >
                                      {baseElement}
                                    </PermissionsGuard>
                                  ) : (
                                    baseElement
                                  );
                                })}
                              </div>
                            </motion.div>
                          )}
                        </div>
                        {item.elements && (
                          <div
                            className="cursor-pointer"
                            onClick={toggleDropdown}
                            onMouseEnter={() => {
                              setHoveredNavItem(item);
                              setClickingDropdownButton(true);
                              isOpen ? null : setSelectedNavItem(item);
                            }}
                          >
                            <button className="flex-initial" type="button">
                              <svg
                                className={classNames(
                                  item.matchRegex.test(asPath) && isOpen
                                    ? "w-2.5 h-2.5 ms-3 fill-gray-900"
                                    : "w-2.5 h-2.5 ms-3 fill-gray-500"
                                )}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m1 1 4 4 4-4"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p
                        className="text-gray-500 pb-7 text-sm font-medium leading-5 hover:text-gray-900 line-clamp-1 cursor-pointer"
                        onClick={() => {
                          setSelectedNavItem(item);
                        }}
                        key={item.name}
                      >
                        {item.name}
                      </p>
                    )
                  ) : (
                    <p
                      className="text-sm font-medium leading-5 line-clamp-1 text-gray-300 pb-7"
                      key={item.name}
                    >
                      {item.name}
                    </p>
                  )
                )}
              </Popover.Group>
              <div className="hidden md:flex self-center items-center justify-end">
                <div className="flex justify-center items-center rounded-full h-full gap-6">
                  <PermissionsGuard
                    permission={(permissionList, role) =>
                      role === BrokerRole.BACK_OFFICE_STAFF ||
                      role === BrokerRole.OWNER ||
                      permissionList.includes(Permission.VIEWING_AS)
                    }
                  >
                    {brokerIdToImpersonate ? (
                      <ViewingAsSelect
                        setViewAsModalShown={setViewAsModalShown}
                      />
                    ) : !alerted ? (
                      <ViewingAsAlert
                        setViewAsModalShown={setViewAsModalShown}
                      />
                    ) : (
                      <ViewingAsEnable
                        setViewAsModalShown={setViewAsModalShown}
                      />
                    )}
                  </PermissionsGuard>
                  <Image
                    alt="user profile photo"
                    src={userPhoto ?? "/images/avatar.png"}
                    blurDataURL="/images/avatar.png"
                    placeholder="blur"
                    height="36px"
                    width="36px"
                    className="inline-block rounded-full"
                  />
                </div>
                <div className="">{ContextMenu}</div>
              </div>
            </div>
            {breadCrumbItem && breadCrumbItem.name !== "Home" && (
              <div className="h-8 flex my-2" key={breadCrumbItem.name}>
                <nav className="flex">
                  <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                      <Link href={paths.dashboard()}>
                        <a
                          className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900"
                          onClick={() => setBreadCrumbItem(null)}
                        >
                          Home
                        </a>
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <svg
                          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link href={breadCrumbItem?.href!}>
                          <a
                            className={classNames(
                              selectedNavItemElement
                                ? "text-gray-500 hover:text-gray-900"
                                : "bg-blue-100  px-1 py-0.5 text-gray-900 rounded-md",

                              "inline-flex items-center text-sm font-medium "
                            )}
                          >
                            {breadCrumbItem?.name}
                          </a>
                        </Link>
                      </div>
                    </li>
                    {selectedNavItemElement && (
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-Width="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <Link href={selectedNavItemElement.href}>
                            <a className="inline-flex items-center text-sm font-medium bg-blue-100 px-1 py-0.5 text-gray-900 rounded-md">
                              {selectedNavItemElement?.name}
                            </a>
                          </Link>
                        </div>
                      </li>
                    )}
                  </ol>
                </nav>
              </div>
            )}
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-50 top-0 inset-x-0 transition transform origin-top-right md:hidden"
            >
              <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div className="">
                      <LogoLetter></LogoLetter>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      {navigation.map((item) =>
                        item.enabled ? ( // FIXME!!!
                          item.href ? (
                            <div className="flex items-center" key={item.name}>
                              <div>
                                <div className="flex items-center">
                                  <Link
                                    href={item.href}
                                    key={item.name}
                                    className=""
                                  >
                                    <a
                                      onClick={(e) => {
                                        setBreadCrumbItem(item);
                                      }}
                                      onMouseEnter={(e) => {
                                        const foundHoveredNavItem =
                                          navigation.find(
                                            (navItem) =>
                                              navItem.href ===
                                              e.currentTarget.pathname
                                          );
                                        if (foundHoveredNavItem)
                                          setHoveredNavItem(
                                            foundHoveredNavItem
                                          );
                                      }}
                                    >
                                      {item.name}
                                    </a>
                                  </Link>
                                  <div className="pl-10">
                                    {item.elements && (
                                      <button
                                        className="flex-initial"
                                        type="button"
                                        onClick={toggleMobileDropdown}
                                        onMouseEnter={() => {
                                          setHoveredNavItem(item);
                                          isMobileOpen
                                            ? null
                                            : setSelectedNavItem(item);
                                        }}
                                      >
                                        <svg
                                          className={classNames(
                                            item.matchRegex.test(asPath) &&
                                              isMobileOpen
                                              ? "w-2.5 h-2.5 ms-3 fill-gray-900"
                                              : "w-2.5 h-2.5 ms-3 fill-gray-500"
                                          )}
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 4 4 4-4"
                                          />
                                        </svg>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                {isMobileOpen &&
                                  selectedNavItem?.name === item.name && (
                                    <div className="w-56 mt-8">
                                      <div className="bottom-10 z-200">
                                        {item?.elements?.map((elem) => {
                                          const baseElement = elem.enabled ? (
                                            <Link
                                              href={elem.href}
                                              key={elem.name}
                                            >
                                              <a
                                                className="text-gray-500 p-2 text-sm font-medium leading-5 hover:text-gray-900 line-clamp-2 rounded-md"
                                                onClick={() => {
                                                  elem.onClick;
                                                  setMobileIsOpen(false);
                                                  setBreadCrumbItem(item);
                                                }}
                                              >
                                                {elem.name}
                                              </a>
                                            </Link>
                                          ) : (
                                            <motion.p
                                              className="ml-8 text-sm p-2 font-medium leading-5 line-clamp-1 text-gray-300"
                                              key={elem.name}
                                              initial={{ opacity: 0 }}
                                              animate={{ opacity: 1 }}
                                            >
                                              {elem.name}
                                            </motion.p>
                                          );

                                          return elem.permission ? (
                                            <PermissionsGuard
                                              key={elem.name}
                                              permission={elem.permission}
                                            >
                                              {baseElement}
                                            </PermissionsGuard>
                                          ) : (
                                            baseElement
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          ) : null
                        ) : (
                          <p
                            className="-m-3 -mx-5 py-3 flex items-center text-base pl-3 leading-6 font-medium text-gray-300"
                            key={item.name}
                          >
                            {item.name}
                          </p>
                        )
                      )}
                    </nav>
                  </div>
                </div>

                <div className="py-6 px-5 space-y-6">
                  <div>
                    <span className="flex">
                      <div className="flex h-full">
                        <Image
                          alt="user profile photo"
                          src={userPhoto ?? "/images/avatar.png"}
                          blurDataURL="/images/avatar.png"
                          placeholder="blur"
                          height="40px"
                          width="40px"
                          className="rounded-full"
                        />
                      </div>
                      <Menu
                        as="div"
                        className="flex flex-col  justify-center text-left "
                      >
                        <div>
                          <Menu.Button className="flex items-center  relative text-gray-300 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="flex-shrink-0 h-6 w-6 text-gray-300"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-left absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            <div className="py-1">
                              {resources.map(({ name, onClick }) => (
                                <Menu.Item key={name}>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm w-full text-left"
                                      )}
                                      onClick={onClick}
                                    >
                                      {name}
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <div className="flex flex-col ml-3 justify-between">
                        <p className=" text-base text-center align-top leading-6 font-medium text-gray-700">
                          {userName}
                        </p>
                        <p className="text-sm align-bottom font-medium leading-5 text-gray-500">
                          Mail
                        </p>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <BookmarkBar />
      </div>
      <div className="[overflow-anchor:none]">{children}</div>
      <div>
        {viewAsModalShown ? (
          <ReusableModal
            onClose={() => setViewAsModalShown(false)}
            size="small"
            content={
              <GenericModalContent title="Viewing as:">
                <SelectOrganizationAndBrokers
                  brokers={
                    brokersData?.getBrokers.filter(
                      (b) => b.brokerId !== getUserId()
                    ) ?? []
                  }
                  onOrganizationSelectionConfirmed={(organization: IOption) => {
                    setOrganizationToImpersonate(organization.value as string);
                    if (organization.value)
                      getBrokers({
                        variables: {
                          id: organization.value as string,
                        },
                      });
                  }}
                  organizationToImpersonate={organizationToImpersonate}
                  brokerIdToImpersonate={brokerIdToImpersonate}
                  onBrokerSelectionConfirmed={(
                    organization: string,
                    brokerId: string
                  ) => {
                    handleImpersonate(organization, brokerId);
                    setAlerted(true);
                    setViewAsModalShown(false);
                    router.replace(router.asPath);
                  }}
                  onClearBrokerSelection={() => {
                    handleImpersonate(undefined, undefined);
                    setAlerted(true);
                    setViewAsModalShown(false);
                  }}
                  organizations={
                    organizationsData?.getOrganizationsByBrokerId ?? []
                  }
                ></SelectOrganizationAndBrokers>
              </GenericModalContent>
            }
          ></ReusableModal>
        ) : null}
      </div>
    </>
  );
}

export default Headbar;
