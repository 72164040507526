import { useUserContext } from "@/context/UserContext";
import { PencilIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, SetStateAction } from "react";

interface IViewingAsSelectProps {
  setViewAsModalShown: Dispatch<SetStateAction<boolean>>;
}

function ViewingAsSelect({ setViewAsModalShown }: IViewingAsSelectProps) {
  const { user } = useUserContext();

  return (
    <div className="flex items-center space-x-4 p-2 bg-shades-azureishWhite border-1 border-secondary-lightBlue rounded-lg shadow-sm">
      <p className="text-primary-blue text-xs font-medium">Viewing as:</p>
      <div
        className="flex items-center space-x-2 bg-white border-1 border-gray-200 px-4 py-2 rounded-lg shadow-sm cursor-pointer"
        onClick={() => setViewAsModalShown(true)}
      >
        <p className="text-gray-500 text-xs font-normal">
          {user?.givenName} {user?.surName}
        </p>
        <PencilIcon className="h-3 w-3 text-gray-500 cursor-pointer" />
      </div>
    </div>
  );
}

export default ViewingAsSelect;
