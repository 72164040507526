import { Permission } from "@/constants/permission";
import { usePermissionContext } from "@/context/PermissionContext";
import { BrokerRole } from "@/types/User";

interface Props {
  permission:
    | Permission
    | ((permissionList: Permission[], role: BrokerRole) => boolean);
  children: React.ReactElement;
}

const PermissionsGuard = ({ permission, children }: Props) => {
  const { hasPermission } = usePermissionContext();

  if (hasPermission(permission)) {
    return children;
  } else {
    return <></>;
  }
};

export default PermissionsGuard;
