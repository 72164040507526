import { useEffect, useState } from "react";

import { IOption } from "@/types/Option";
import SingleSelect from "./SingleSelect";
import ReusableButton from "./ReusableButton";

import { IOrganization } from "@/types/Organization";
import { DropdownItem } from "./Dropdown";
import { IBrokerDetails } from "@/types/User";

interface ISelectProductOfGroupProps {
  organizationToImpersonate?: string;
  brokerIdToImpersonate?: string;
  onOrganizationSelectionConfirmed: (oganization: IOption) => void;
  onBrokerSelectionConfirmed: (
    organizationId: string,
    brokerId: string
  ) => void;
  onClearBrokerSelection: () => void;
  organizations: IOrganization[];
  brokers?: IBrokerDetails[];
}

const SelectOrganizationAndBrokers = ({
  organizationToImpersonate,
  brokerIdToImpersonate,
  onOrganizationSelectionConfirmed,
  onBrokerSelectionConfirmed,
  onClearBrokerSelection,
  organizations,
  brokers,
}: ISelectProductOfGroupProps) => {
  const impersonatedOrganization = organizations.find(
    (org) => org.id === organizationToImpersonate
  );
  const impersonatedBroker = brokers?.find(
    (b) => b.brokerId === brokerIdToImpersonate
  );

  const displayBrokerName = (broker: IBrokerDetails) => {
    return `${broker.givenName} ${broker.surName} (${broker.mail})`;
  };

  const [selectedOrganizationOption, setSelectedOrganizationOption] = useState<
    IOption | undefined
  >(
    impersonatedOrganization
      ? {
          text: impersonatedOrganization.name,
          value: impersonatedOrganization.id,
        }
      : undefined
  );

  const [selectedBroker, setSelectedBroker] = useState<IOption | undefined>(
    impersonatedBroker
      ? {
          text: displayBrokerName(impersonatedBroker),
          value: impersonatedBroker.brokerId,
        }
      : undefined
  );

  const handleOrganizationOptionChange = (option: IOption) => {
    setSelectedOrganizationOption(option);
    onOrganizationSelectionConfirmed(option);
  };

  useEffect(() => {
    if (organizationToImpersonate) {
      const impersonatedOrganizationOption = castToOptions(organizations).find(
        (org) => org.value === organizationToImpersonate
      );

      if (impersonatedOrganizationOption)
        handleOrganizationOptionChange(impersonatedOrganizationOption);
    }
  }, []);

  const castToOptions = (orgs: IOrganization[]): IOption[] => {
    return orgs
      .reduce<IOrganization[]>((acc, next): IOrganization[] => {
        return [...acc, next, ...next.agencies];
      }, [])
      .sort((mapA, mapB) =>
        mapA.name.charAt(0).localeCompare(mapB.name.charAt(0))
      )
      .map(
        (organization): IOption => ({
          text: organization.name,
          value: organization.id,
        })
      );
  };

  return (
    <div className="flex flex-col justify-center items-center mb-5 w-full">
      <div className="space-y-6 text-gray-500  w-full px-20">
        <section>
          <label
            htmlFor="group"
            className="block text-sm  mb-1 font-medium w-full"
          >
            Select an Agency
          </label>
          <SingleSelect
            options={castToOptions(organizations)}
            onChange={(option) => {
              handleOrganizationOptionChange(option);
              setSelectedBroker(undefined);
            }}
            selected={selectedOrganizationOption}
            selectHeight="h-9.5"
          />
        </section>

        <section>
          <label htmlFor="group" className="block text-sm  mb-1 font-medium">
            Select Agents
          </label>
          <SingleSelect
            options={
              brokers
                ?.map(
                  (broker) =>
                    ({
                      text: displayBrokerName(broker),
                      value: broker.brokerId,
                    } as DropdownItem)
                )
                ?.sort((mapA, mapB) =>
                  mapA.text.charAt(0).localeCompare(mapB.text.charAt(0))
                ) ?? []
            }
            selectHeight="h-9.5"
            selected={selectedBroker}
            onChange={(value: IOption) => {
              setSelectedBroker(value);
            }}
          />
        </section>
        <div className="flex gap-2">
          <ReusableButton
            buttonStyles={`h-8 p-2 border rounded cursor-pointer w-2/3 bg-secondary-lightBlue border-secondary-lightBlue flex justify-center items-center disabled:opacity-40`}
            textStyles="text-white whitespace-nowrap text-[0.8rem] sm:text-[0.9rem] font-interLight"
            text="Accept"
            disabled={!selectedBroker}
            showLoader={false}
            onClick={() => {
              if (!selectedOrganizationOption || !selectedBroker) return;
              onBrokerSelectionConfirmed(
                selectedOrganizationOption.value as string,
                selectedBroker.value as string
              );
            }}
          />
          <ReusableButton
            buttonStyles={`h-8 p-2 border rounded cursor-pointer w-1/3 bg-gray-400 flex justify-center items-center disabled:opacity-40`}
            textStyles="text-white whitespace-nowrap text-[0.8rem] sm:text-[0.9rem] font-interLight"
            text="Clear"
            showLoader={false}
            onClick={onClearBrokerSelection}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectOrganizationAndBrokers;
