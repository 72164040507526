import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { IOption } from "../../types/Option";

interface SingleSelectProps {
  selected?: IOption | string;
  showTextAsValue?: boolean;
  onChange: (option: IOption) => void;
  options: (IOption & { dataCy?: string })[];
  selectHeight?: string;
  extraClasses?: string;
  disabled?: boolean;
  center?: boolean;
  dataCy?: string;
}

const SingleSelect = ({
  showTextAsValue,
  options,
  selected,
  onChange,
  selectHeight,
  extraClasses = "",
  disabled,
  center = false,
  dataCy,
}: SingleSelectProps) => {
  let selectedOption;

  if (typeof selected === "string")
    selectedOption = options.find((option) => option.value === selected);
  else selectedOption = selected;

  return (
    <Listbox
      value={selected}
      onChange={onChange}
      disabled={disabled}
      data-cy={`select-${dataCy}`}
    >
      <div className={`relative ${extraClasses}`}>
        <Listbox.Button
          className={`relative w-full py-2 pl-3 pr-2 text-left border-1 border-solid border-gray-300 rounded-md shadow-sm bg-white cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-md disabled:bg-gray-100 disabled:opacity-75 ${
            selectHeight ? selectHeight : "h-12"
          }`}
        >
          <div className="flex justify-between">
            <span className="block truncate font-interRegular text-sm leading-5 font-normal text-gray-700">
              {showTextAsValue ? selectedOption?.value : selectedOption?.text}
            </span>
            <ChevronDownIcon
              className="w-5 h-5 text-gray-700"
              aria-hidden="true"
            />
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, optionIdx) => (
              <Listbox.Option
                key={optionIdx}
                className={({ active }) =>
                  `${active ? "text-indigo-900 bg-indigo-100" : "text-gray-900"}
                            z-50 cursor-default select-none relative py-2 ${
                              !center && "pl-10 pr-4"
                            }`
                }
                value={option}
                data-cy={`option-${option.dataCy}`}
              >
                {({ selected, active }) => (
                  <span className={`${center && "flex justify-center"}`}>
                    <span className="font-medium text-gray-800 sm:text-md block truncate">
                      {showTextAsValue ? option.value : option.text}
                    </span>
                    {selected ? (
                      <span
                        className={`text-gray-500 absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SingleSelect;
