import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { motion } from "framer-motion";
import { MouseEventHandler } from "react";

export enum ProgressItemStatus {
  COMPLETED = "COMPLETED",
  ONGOING = "ONGOING",
  UPCOMING = "UPCOMING",
}

interface IProgressItemProps {
  title: string;
  description: string;
  status: ProgressItemStatus;
  showText: boolean;
  isLeftItem?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
}

function ProgressItemMinimal({
  title,
  description,
  status,
  showText,
  isLeftItem = true,
  onClick,
}: IProgressItemProps) {
  return (
    <div
      className={classNames("relative flex justify-center w-full z-20")}
      onClick={onClick}
    >
      <span className="flex flex-col items-center">
        <span
          className={classNames(
            "flex items-center justify-center shrink-0 h-7 w-7",
            {
              "bg-secondary-lightBlue rounded-full":
                status === ProgressItemStatus.COMPLETED,
              "border-secondary-lightBlue bg-white border-2 rounded-full":
                status === ProgressItemStatus.ONGOING,
              "border-gray-300 bg-white border-2 rounded-full":
                status === ProgressItemStatus.UPCOMING,
            },
            {
              "after:bg-secondary-lightBlue after:absolute after:top-3.5 after:left-0 after:w-[calc(50%)] after:h-0.5 after:z-[-1]":
                status === ProgressItemStatus.COMPLETED ||
                status === ProgressItemStatus.ONGOING,
              "after:bg-gray-300 after:absolute after:top-3.5 after:left-0 after:w-[calc(50%)] after:h-0.5 after:z-[-1]":
                status === ProgressItemStatus.UPCOMING,
              "before:bg-secondary-lightBlue before:absolute before:top-3.5 before:right-0 before:w-[calc(50%)] before:h-0.5 before:z-[-1]":
                status === ProgressItemStatus.COMPLETED,
              "before:bg-gray-300 before:absolute before:top-3.5 before:right-0 before:w-[calc(50%)] before:h-0.5 before:z-[-1]":
                status === ProgressItemStatus.UPCOMING ||
                status === ProgressItemStatus.ONGOING,
            }
          )}
        >
          {status === ProgressItemStatus.COMPLETED ? (
            <CheckIcon width={18} height={18} color="white" />
          ) : status === ProgressItemStatus.ONGOING ? (
            <span className="bg-secondary-lightBlue rounded-full w-2.5 h-2.5"></span>
          ) : null}
        </span>
        {showText && (
          <ChevronDownIcon
            width={16}
            height={16}
            className={classNames(
              "absolute top-full left-1/2 translate-x-[-50%]",
              {
                "text-gray-400": status === ProgressItemStatus.UPCOMING,
                "text-secondary-lightBlue":
                  status === ProgressItemStatus.ONGOING ||
                  status === ProgressItemStatus.COMPLETED,
              }
            )}
          />
        )}
      </span>
      {showText && (
        <motion.div
          className={classNames(
            "absolute top-5 flex flex-col w-64 pt-5 text-sm z-[-1]",
            {
              "left-2 text-left": isLeftItem,
              "right-2 text-right": !isLeftItem,
            }
          )}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
        >
          <div
            className={classNames("uppercase", {
              "text-gray-900": status === ProgressItemStatus.COMPLETED,
              "text-secondary-lightBlue": status === ProgressItemStatus.ONGOING,
              "text-gray-500": status === ProgressItemStatus.UPCOMING,
            })}
          >
            {title}
          </div>
          <div className="font-light text-gray-500">{description}</div>
        </motion.div>
      )}
    </div>
  );
}

export default ProgressItemMinimal;
