export const myBusinessIndexParamKeys = [] as const;
export const prospectsIndexParamKeys = [] as const;
export const pendingCasesIndexParamKeys = [] as const;
export const agentDocumentsIndexParamKeys = [] as const;

export const materialsIndexParamKeys = [] as const;
export const customerMaterialsGalleryParamKeys = [
  "state",
  "productId",
] as const;
export const groupMaterialsGalleryParamKeys = ["productId"] as const;
export const producerMaterialsGalleryParamKeys = ["productId"] as const;
export const applicationMaterialsGalleryParamKeys = ["productId"] as const;

export const individualIndexParamKeys = [] as const;
export const salesIllustrationIndexParamKeys = [] as const;
export const individualRateSheetCreateParamKeys = [] as const;
export const individualRateCalculatorParamKeys = [] as const;

export const groupsIndexParamKeys = [] as const;
export const myGroupsIndexParamKeys = [] as const;
export const groupQuotesIndexParamKeys = [] as const;
export const groupQuotesCreateParamKeys = [] as const;
export const groupRateSheetCreateParamKeys = [] as const;

export const articlesViewParamKeys = ["articleId"] as const;

export const brokerDetailsParamKeys = [] as const;

export const prospectViewParamKeys = ["id"] as const;
