import { CheckIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import tw from "twin.macro";

export enum ProgressItemStatus {
  COMPLETED = "COMPLETED",
  ONGOING = "ONGOING",
  UPCOMING = "UPCOMING",
}

interface IProgressItemProps {
  title: string;
  description: string;
  status: ProgressItemStatus;
  lastItem?: boolean;
  index: number;
}

function ProgressItem({
  title,
  description,
  status,
  lastItem,
  index,
}: IProgressItemProps) {
  const getVariantFromStatus = (
    status: ProgressItemStatus
  ): keyof typeof variants => {
    switch (status) {
      case ProgressItemStatus.COMPLETED:
        return "completed";
      case ProgressItemStatus.ONGOING:
        return "ongoing";
      case ProgressItemStatus.UPCOMING:
        return "upcoming";
    }
  };
  const variants = {
    completed: {
      ...tw`flex items-center justify-center shrink-0 h-7 w-7 rounded-full`,
      backgroundColor: "#0085C6",
    },
    ongoing: {
      ...tw`flex items-center justify-center shrink-0 h-7 w-7 border-2 rounded-full before:content-[''] before:absolute before:top-3.5 before:left-3.5 before:-translate-x-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:bg-[#0085C6] before:rounded-[50%]`,
      backgroundColor: "#FFFFFF",
      borderColor: "#0085C6",
    },
    upcoming: {
      ...tw`flex items-center justify-center shrink-0 h-7 w-7 border-gray-300 bg-white border-2 rounded-full`,
      backgroundColor: "#FFFFFF",
      borderColor: "#E0E0E0",
    },
  };
  return (
    <div className="relative flex space-x-4">
      <AnimatePresence>
        <motion.span
          className={classNames({
            "after:bg-secondary-lightBlue after:absolute after:top-7 after:w-0.5 after:h-[calc(100%+12px)]":
              status === ProgressItemStatus.COMPLETED && !lastItem,
            "after:bg-gray-300 after:absolute after:top-7 after:w-0.5 after:h-[calc(100%+12px)]":
              (status === ProgressItemStatus.UPCOMING ||
                status === ProgressItemStatus.ONGOING) &&
              !lastItem,
          })}
          initial={getVariantFromStatus(status)}
          animate={getVariantFromStatus(status)}
          variants={variants}
          key={`completed-${index}`}
          transition={{ duration: 0.5 }}
        >
          <AnimatePresence>
            {status === ProgressItemStatus.COMPLETED ? (
              <CheckIcon
                width={18}
                height={18}
                color="white"
                key={`check-${index}`}
              />
            ) : (
              status === ProgressItemStatus.ONGOING && (
                <motion.div
                  className="before:content-[''] before:absolute before:top-3.5 before:left-3.5 before:-translate-x-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:bg-[#0085C6] before:rounded-[50%]"
                  key={`dot-${index}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                ></motion.div>
              )
            )}
          </AnimatePresence>
        </motion.span>
      </AnimatePresence>
      <div className="flex flex-col text-sm">
        <div
          className={classNames("uppercase", {
            "text-gray-900": status === ProgressItemStatus.COMPLETED,
            "text-secondary-lightBlue": status === ProgressItemStatus.ONGOING,
            "text-gray-500": status === ProgressItemStatus.UPCOMING,
          })}
        >
          {title}
        </div>
        <div
          className={classNames("font-light text-gray-500", {
            "text-gray-900": status === ProgressItemStatus.COMPLETED,
            "text-secondary-lightBlue": status === ProgressItemStatus.ONGOING,
            "text-gray-500": status === ProgressItemStatus.UPCOMING,
          })}
        >
          {description}
        </div>
      </div>
    </div>
  );
}

export default ProgressItem;
